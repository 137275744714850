import React, { useState, useRef, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import fetchy from './lib/fetchy.js';
import { Time } from './lib/Time.js';
import { notifyRefer, notifyReferTarget } from './lib/notify.js';

const Interface = lazy(() => import('./components/Interface'));
const SetRefer = lazy(() => import('./components/SetRefer'));
const LoginSignup = lazy(() => import('./pages/LoginSignup'));
const Home = lazy(() => import('./pages/Home'));

const WARMUP_SECS = JSON.parse(process.env.REACT_APP_WARMUP_SECS);

const App = () =>
{
	const [streak, setStreak] = useState();
	const [hearts, setHearts] = useState();
	const [planExpires, setPlanExpires] = useState();
	const [planName, setPlanName] = useState('init_visionary');
	const email = useRef();
	const blockedUsers = useRef([]);

	const [unseen, setUnseen] = useState();
	const [guilds, setGuilds] = useState();
	const [quillmate, setQuillmate] = useState();
	const [levels, setLevels] = useState();
	const [totalWords, setTotalWords] = useState();

	const sprintSeconds = useRef(parseFloat(process.env.REACT_APP_SPRINT_SECS,10));
	const time = useRef(new Time(Date.now()));
	const doneFirstLoad = useRef(false);
	const userId = useRef();
	const welcome = useRef();
	const anonymous = useRef(false);

	useEffect(() =>
	{
		if(doneFirstLoad.current) return;
		doneFirstLoad.current = true;

		(async ()=>
		{
			const response = await fetchy.get(`${process.env.REACT_APP_BACKEND_SERVER}/getuserinfo`);
			if(response.data.anonymous)
			{
				setPlanName();
				anonymous.current = true;
				return;
			}

			const {streak, hearts, words, planExpires, planName, now, unseen, guilds, quillmate, levels, todaySprints, resetTrial, ifRefer, ifReferTarget} = response.data;
			userId.current = response.data.userId;
			email.current = response.data.email;
			blockedUsers.current = response.data.blockedUsers;
			setStreak({days:streak, noUpdate:true});
			setHearts(hearts);
			setTotalWords(words);
			setPlanName(planName);
			if(planExpires) setPlanExpires(new Date(planExpires));
			if(todaySprints<WARMUP_SECS.length) sprintSeconds.current = WARMUP_SECS[todaySprints];
			time.current = new Time(now);

			const welcomeDate = new Date(response.data.last_welcome_time);
			welcome.current = (
				welcomeDate.toLocaleDateString() !== new Date().toLocaleDateString() && 
				(time.current.now() - welcomeDate.getTime()) >=4*60*60*1000
			) ? response.data.welcome : undefined;

			setLevels(levels);
			setUnseen(unseen);
			setGuilds(guilds?.map(e => ({...e, noUpdate:true})));
			if(quillmate) setQuillmate({...quillmate, noUpdate:true});

			console.log({ifRefer,ifReferTarget,s:sessionStorage.notifyRefer});
			if((ifRefer||ifReferTarget) && !sessionStorage.notifyRefer) 
			{
				if(ifRefer) notifyRefer();
				else notifyReferTarget();
				sessionStorage.notifyRefer = 1;
			}
			if(resetTrial && !localStorage.tutorial)
			{
				window.location = '/tutorial';
				localStorage.tutorial = 1;
			}
		})();
	}, [doneFirstLoad, anonymous, email, sprintSeconds, time, setPlanExpires]);

	return (
		<BrowserRouter>
		<Suspense fallback={<img src="/images/icons/sleepy.gif" alt="Loading..." />}>
			<Routes>
				<Route path="/" element={<><Home {...{ planName, currentGuilds:guilds, setCurrentGuilds:setGuilds, time }} /><SetRefer /></>} />
				
				<Route path="/login" element={<><LoginSignup mode="login" /><SetRefer /></>} />
				<Route path="/signup" element={<><LoginSignup mode="signup" /><SetRefer /></>} />
				<Route path="/resetpassword1" element={<><LoginSignup mode="resetpassword1" /><SetRefer /></>} />
				<Route path="/resetpassword2" element={<><LoginSignup mode="resetpassword2" /><SetRefer /></>} />
				<Route path="/:page/:urlId?" element={ <><Interface {...{ userId, email, streak, setStreak, hearts, setHearts, totalWords, setTotalWords, planName, planExpires, levels, setLevels, unseen, guilds, setGuilds, quillmate, setQuillmate, sprintSeconds, blockedUsers, welcome, anonymous, time }} /><SetRefer /></> } />

			</Routes>
		</Suspense>
		<ToastContainer position="top-center" autoClose={10000} />
		</BrowserRouter>
	);
}

export default App;
