import axios from 'axios';
import { notifyError } from './notify';
import { sleep } from './utils';

const SSE_REGEX = /^data: ([^\r\n]+$)/gm;
const MAX_RETRIES = 5;

/**
 * Custom axios handler that gracefully handles error messages and redirects to the login page as needed.
 */
const fetchy =
{
	retryCount: 0,

	/**
	 * Wrapper for axios get
	 *
	 * @param {string} url
	 * @param {Object} [options] - Additional axios options
	 * @returns {Promise<response>} Response from axios
	 * @throws {Error} after displaying a toast message
	 **/
	get: async function(url, options)
	{
		try
		{
			const response = await axios.get(url, {...options, withCredentials:true});
			return response;
		} catch (error)
		{
			const result = await this.handleError(error, options);
			if(result==='retry') return this.get(url, options);
		}
	},

	/**
	 * Wrapper for axios post
	 *
	 * @param {string} url
	 * @param {Object} [data] - To be sent with the POST request.
	 * @param {Object} [options] - Additional axios options
	 * @returns {Promise<response>} Response from axios
	 * @throws {Error} after displaying a toast message
	 **/
	post: async function(url, data, options)
	{
		try
		{
			const response = await axios.post(url, data, {...options, withCredentials:true});
			return response;
		} catch (error)
		{
			const result = await this.handleError(error, options);
			if(result==='retry') return this.post(url, data, options);
		}
	},

	/**
	 * Initializes a stream from a POST API endpoint that responds with server-sent events
	 *
	 * @param {string} url
	 * @param {Object} [data] - To be sent with the POST request.
	 * @param {Object} [callback] - Function to be called after the stream closes
	 * @returns {Promise<AsyncGenerator>} - The response stream
	 * @throws {Error} after displaying a toast message
	 **/
	stream: async function(url, data, callback, options)
	{
		try
		{
			const response = await fetch(url, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});

			if(!response.ok) throw await response.text();

			const reader = response.body.getReader();
			const decoder = new TextDecoder('utf-8');

			async function* responseGenerator()
			{
				while (true)
				{
					const { done, value } = await reader.read();

					if (done)
					{
						if(callback) callback();
						break;
					}

					const chunks = [...decoder.decode(value).matchAll(SSE_REGEX)]
						.map((match) =>
						{
							try
							{
								return JSON.parse(match[1])
							} catch
							{
								return match[1]
							}
						}).filter(e=>e);

					for(const chunk of chunks)
					{
						yield chunk;
					}
				}
			}

			return responseGenerator();
		} catch (error)
		{
			console.error(error);
			const result = await this.handleError(error, options);
			if(result==='retry') return this.stream(url, data, callback, options);
		}
	},

	/**
	 * Handle errors and display error messages through react-toastify.
	 *
	 * @param {Error} error - The error object.
	 */
	handleError: async function(error, options)
	{
		if (error?.response?.status === 403)
		{
			window.location.replace("/");
		}

		if((error?.code==='ERR_NETWORK'||error?.code==='ECONNABORTED'||error?.code==='ETIMEDOUT') && this.retryCount<MAX_RETRIES)
		{
			this.retryCount++;
			await sleep(this.retryCount*100);
			console.log(`Retrying... (${this.retryCount}/${MAX_RETRIES})`);
			return 'retry';
		}
		if(error?.code!=='ERR_CANCELED' && (!options?.silent || error?.response?.data?.nosilent))
		{
			notifyError(error?.response?.data?.message || error?.message || "Request failed");
		}
		throw error;
	}
};

export default fetchy;
