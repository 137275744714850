import fetchy from './fetchy.js';

export const sleep = ms => new Promise(r => setTimeout(r, ms));
export const randArray = array => array[Math.floor(Math.random() * array.length)];

export const shuffle = array =>
{
	for (let i=array.length-1; i>=0; i--)
	{
		const j = Math.floor(Math.random() * (i+1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}

/**
 * Generates a random integer between the specified minimum and maximum values (inclusive).
 * @param {number} min - The minimum value for the range.
 * @param {number} max - The maximum value for the range.
 * @returns {number} A random integer within the specified range.
 */
export const randBetween = (min, max) => Math.round(Math.random() * (max - min) + min);

/**
 * Capitalizes the first character of a given string.
 * @param {String} string - The input string.
 * @returns {String} The string with the first character capitalized.
 */
export const upperFirstChar = string => (string?.charAt(0)?.toUpperCase() + string?.slice(1));

/**
 * Converts the first character of a given string to lowercase.
 * @param {String} string - The input string.
 * @returns {String} The string with the first character converted to lowercase.
 */
export const lowerFirstChar = string => (string?.charAt(0)?.toLowerCase() + string?.slice(1));

export const isStr = string => (typeof string === 'string' || string instanceof String);

export const numToEnglish = num => ['zero','one','two','three','four','five','six','seven','eight','nine','ten'][num];

export const checkLimit = async () => (await fetchy.post(`${process.env.REACT_APP_BACKEND_SERVER}/spark/check`, {}, {quiet:true}))?.data;

// Sets an interval, invoking the callback immediately, up to maxTimes times
export const startInterval = (callback, timeout, maxTimes) =>
{
	let i = 1;
	callback();
	const interval = setInterval(() =>
	{
		callback();
		if(++i >= maxTimes) clearInterval(interval);
		console.log(i);
	}, timeout);

	return interval;
}

export const parseIfJson = (json, fallback) =>
{
	try { return JSON.parse(json); }
	catch { return fallback; }
}

export const last = array => array[array.length-1];

export const sum = arr => arr?.reduce((acc, cur) => acc + cur, 0);
export const avg = arr => sum(arr)/arr.length;

export const getFirstMonth = months => months.reduce((min, obj) => (obj.year < min.year || (obj.year === min.year && obj.month_num < min.month_num)) ? obj : min);
export const getLastMonth = months => months.reduce((max, obj) => (obj.year > max.year || (obj.year === max.year && obj.month_num > max.month_num)) ? obj : max);
export const findMonth = (data,month) => data?.find(e=>e.month===month);
